<script setup lang="ts">
const props = defineProps({
  open: Boolean,
  fullfillPromise: Function,
});

const emit = defineEmits(["update:open"]);

const syncedOpen = computed({
  get: () => props.open,
  set: val => emit("update:open", val),
});

function close() {
  props.fullfillPromise!(false);
  syncedOpen.value = false;
}

function submit() {
  props.fullfillPromise!(true);
  syncedOpen.value = false;
}
</script>

<template>
  <div>
    <v-dialog v-model="syncedOpen" width="400" persistent>
      <v-card>
        <v-card-title>{{ $t("components.confirmCloseDialog.title") }}</v-card-title>
        <v-card-text>{{ $t("components.confirmCloseDialog.body") }}</v-card-text>
        <v-card-actions>
          <v-row no-gutters justify="end">
            <anybill-button text @click="close">
              {{ $t("components.confirmCloseDialog.btns.abort") }}
            </anybill-button>
            <anybill-button @click="submit">
              {{ $t("components.confirmCloseDialog.btns.submit") }}
            </anybill-button>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
