<script setup lang="ts">
const properties = defineProps({
  onDelete: Function,
  onDeleteArgs: Object,
  onAbort: Function,
  name: String,
  deleteText: String,
  open: Boolean,
  secureDelete: Boolean,
});

const emit = defineEmits(["update:open"]);

const syncedOpen = computed({
  get: () => properties.open,
  set: val => emit("update:open", val),
});

const trueOpen = ref(false);
const deleteIsLoading = ref(false);
const secureDeleteOpen = ref(false);

watch(syncedOpen, (change: boolean) => {
  trueOpen.value = change;
}, { immediate: true });

function decideDelete() {
  properties.secureDelete ? makeSecureDelete() : makeDelete();
}

function executeAbort() {
  syncedOpen.value = false;
  secureDeleteOpen.value = false;
  deleteIsLoading.value = false;
  if (properties.onAbort) { properties.onAbort(); }
}

function makeSecureDelete() {
  syncedOpen.value = false;
  secureDeleteOpen.value = true;
}

async function makeDelete() {
  deleteIsLoading.value = true;
  if (properties.onDeleteArgs) { await properties.onDelete!(properties.onDeleteArgs); }
  else { await properties.onDelete!(); }

  deleteIsLoading.value = false;
  syncedOpen.value = false;
  secureDeleteOpen.value = false;
}
</script>

<template>
  <div>
    <v-dialog
      v-model="trueOpen"
      width="400"
      persistent
    >
      <template #activator="{ props }">
        <slot
          name="activator"
          v-bind="props"
        />
      </template>

      <v-card>
        <v-card-title>
          <span
            class="headline"
            style="word-break: break-word"
          >{{
            $t("components.deleteDialog.title", { name })
          }}</span>
        </v-card-title>

        <v-card-text>
          <span
            v-if="deleteText"
            v-html="deleteText"
          />
          <template v-else>
            {{ $t("components.deleteDialog.subtitle") }}
          </template>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <AnybillButton
            id="btn-confirm-abort"
            tabindex="-1"
            text
            @click="executeAbort"
          >
            {{ $t("components.deleteDialog.abortBtn") }}
          </AnybillButton>
          <AnybillButton
            id="btn-confirm-delete"
            tabindex="-1"
            :loading="deleteIsLoading"
            color="error"
            icon="mdi-delete-outline"
            @click="decideDelete"
          >
            {{ $t("components.deleteDialog.deleteBtn") }}
          </AnybillButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="secureDeleteOpen"
      width="400"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t("components.deleteDialog.safeDeleteTitle") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <AnybillButton
            id="btn-confirm-abort"
            tabindex="-1"
            depressed
            @click="executeAbort"
          >
            {{ $t("components.deleteDialog.abortBtn") }}
          </AnybillButton>
          <AnybillButton
            id="btn-confirm-delete"
            tabindex="-1"
            :loading="deleteIsLoading"
            color="error"
            @click="makeDelete"
          >
            <TrashIcon class="mr-2" />
            {{ $t("components.deleteDialog.safeDeleteBtn") }}
          </AnybillButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
