<script setup lang="ts">
</script>

<template>
  <v-row
    no-gutters
    justify="space-between"
  >
    <slot name="ternary" />
    <v-row
      no-gutters
      justify="end"
    >
      <slot name="secondary" />
      <slot name="primary" />
    </v-row>
  </v-row>
</template>
